import React, { useEffect, useState } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/FAQ/PageContents"
import { isBrowser } from "../services/browser"

const FaqPage = ({ location, data }) => {
  const path = location.pathname
  const { page } = data
  const [activeSection, setActiveSection] = useState(null)

  useEffect(() => {
    const currentUrl = new URL(location.href)
    const searchParams = currentUrl.searchParams
    const section = searchParams.get("section")

    if (section && section !== "") {
      setActiveSection(section)
    }
  }, [location.href])

  useEffect(() => {
    if (activeSection) {
      if (isBrowser()) {
        let yOffset =  -200
        let element = document.getElementById(activeSection)
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset
        setTimeout(() => {
          window.scrollTo({ top: y, behavior: "smooth" })
        }, 500)
      }
    }
  }, [activeSection])


  return (
    <Layout>
      <Seo
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
      />
      <PageContents blocks={page.blocks} />
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query FaqPageQuery {
    page: contentfulPage(slug: { eq: "faq" }) {
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulIntroBlock {
          id
          header
          subHeader {
            raw
          }
        }
        ... on ContentfulFaqTopic {
          title
          faqId
          faqs {
            title
            content {
              raw
            }
          }
        }
      }
    }
  }
`
