import React from "react"
import FaqBlock from "./FaqBlock"
import IntroBlock from "./IntroBlock"
import styled from "styled-components"
import ContentContainer from "../Core/Container/ContentContainer"
import BrandPattern from "../../resources/img/patterns/brand-pattern-03.svg"
import breakpoints from "../../styles/breakpoints"

const FaqPageContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const NarrowContainer = styled.div`
  max-width: 881px;
  margin: auto;
`

const Pattern = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: -20%;
  opacity: 0.6;
  z-index: -1;
  img {
    max-width: 150vw;
  }
  @media (min-width: ${breakpoints.md}) {
    left: 0;
    img {
      max-width: 972px;
    }
  }
`

const PageContents = ({ blocks }) => {
  let pageBlocks = []
  let faqTopics = []

  blocks.forEach((block, index) => {
    const type = block.__typename

    if (type === "ContentfulIntroBlock") {
      pageBlocks.push(<IntroBlock {...block} key={index} />)
    }
    if (type === "ContentfulFaqTopic") {
      faqTopics.push(block)
    }
    if (
      faqTopics.length !== 0 &&
      index ===
        blocks.map((el) => el.__typename).lastIndexOf("ContentfulFaqTopic")
    ) {
      pageBlocks.push(<FaqBlock faqTopics={faqTopics} key={index} />)
    }
  })

  return (
    <FaqPageContainer>
      <ContentContainer>
        <NarrowContainer>{pageBlocks}</NarrowContainer>
      </ContentContainer>
      <Pattern>
        <img src={BrandPattern} alt={process.env.WEBSITE_TITLE} />
      </Pattern>
    </FaqPageContainer>
  )
}

export default PageContents
