import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import FaqCard from "./FaqCard"
import breakpoints from "../../styles/breakpoints"

const FaqBlockContainer = styled.div`
  margin-bottom: 133px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 212px;
  }

`

const FaqTopic = styled.div`
  margin-bottom: 154px;
  :last-of-type {
    margin-bottom: 0;
  }

`

const TopicTitle = styled.h2`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  line-height: normal;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  padding-bottom: 49px;
  border-bottom: 1px solid ${colors.grey};
`

const Dropdowns = styled.div`

`

const FaqBlock = (props) => {
  const { faqTopics } = props;

  return (
    <FaqBlockContainer>
        {faqTopics.map((topic, index) => {
          const {title, faqs, faqId} = topic
          return (
            <FaqTopic key={index} id={faqId}>
              <TopicTitle>{title}</TopicTitle>
              <Dropdowns>
                {faqs.map((faq, index) => {
                  return (
                    <FaqCard {...faq} key={index} />
                  )
                })}
              </Dropdowns>
            </FaqTopic>
          )
        })}
    </FaqBlockContainer>
  )
}

export default FaqBlock
