import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ArrowDown from "../../resources/img/ui/dark/chevron-down-dark.svg"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"

const FaqContainer = styled.div`
  border-bottom: 1px solid ${colors.grey};
  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 20px;
  }
`

const FaqTitle = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 18px;
  line-height: 1.6;
  padding: 44px 0 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 26px;
  }
`

const Arrow = styled.div`
  margin-left: 57px;
  img {
    transition: transform 0.25s ease-in;
    transform: ${(props) =>
      props.isDisplayed ? "rotate(180deg)" : "rotate(0)"};
  }
`

const Drawer = styled.div`
  max-height: ${(props) => (props.open ? `${props.maxHeight}px` : "0px")};
  transition: all 0.25s ease-in;
  overflow: hidden;
`

const FaqContent = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 25px;
  padding: 11px 0 70px;

  p {
    margin-bottom: 25px;
    :last-of-type {
      margin-bottom: 0;
    }

    b {
      ${fonts.labGrotesqueBold};
    }

    i {
      font-style: italic;
    }

    a {
      color: inherit;
    }
  }

  img {
    max-width: 400px;
    width: 100%;
    margin: 10px auto;
  }

  ul {
    li {
      list-style: disc;
      margin-left: 20px;
      margin-bottom: 25px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    padding-top: 0;
    padding-bottom: 50px;
  }
`

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const altText = node.data?.target?.fields?.title["en-US"] ? node.data?.target?.fields?.title["en-US"] : node.data?.target?.fields?.file["en-US"].title
      return (
        <img src={node.data?.target?.fields?.file["en-US"].url}
             alt={altText}/>
      )
    },
  },
}

const FaqCard = (props) => {
  const { title, content } = props
  const [isOpen, setIsOpen] = useState(false)
  const [answerHeight, setAnswerHeight] = useState(0)
  let answerRef = useRef(null)

  const handleAnswerHeight = () => {
    if (answerRef) {
      setAnswerHeight(answerRef.clientHeight)
    }
  }

  useEffect(() => {
    if (answerRef) {
      setAnswerHeight(answerRef.clientHeight)
    }
  }, [answerRef])

  useEffect(() => {
    window.addEventListener("resize", handleAnswerHeight)
    return () => {
      window.removeEventListener("resize", handleAnswerHeight)
    }
  })

  return (
    <FaqContainer>
      <FaqTitle onClick={() => setIsOpen(!isOpen)} >
        <div>{title}</div>
        <Arrow isDisplayed={isOpen ? 1 : 0}>
          <img src={ArrowDown} alt="Arrow" />
        </Arrow>
      </FaqTitle>
      <Drawer open={isOpen ? 1 : 0} maxHeight={answerHeight.toString()}>
        <FaqContent ref={(el) => (answerRef = el)}>
          {renderRichText(content, options)}
        </FaqContent>
      </Drawer>
    </FaqContainer>
  )
}

export default FaqCard
