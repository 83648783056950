import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const IntroBlockContainer = styled.div`
  text-align: center;
  margin: 95px 0 189px;
  padding: 0 25px;
    @media (min-width: ${breakpoints.md}) {
      margin: 167px 0 139px;
  }

`

const Title = styled.h1`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 1;

  @media (min-width: ${breakpoints.md}) {
    font-size: 72px;
  }
`

const SubHeader = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  line-height: 1.6;
  margin-top: 26px;
  color: ${colors.grey};
  a {
    color: inherit;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 660px;
    margin: 24px auto 0;
  }
`

const IntroBlock = (props) => {
  const { header, subHeader } = props
  
  return (
    <IntroBlockContainer>
        <Title>{header}</Title>
        {subHeader && (
          <SubHeader>{renderRichText(subHeader)}</SubHeader>
        )}
    </IntroBlockContainer>
  )
}

export default IntroBlock
